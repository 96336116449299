// URLs del Servidor API
import { HttpHeaders } from '@angular/common/http';

const url = 'https://espaciourbanos.cl/';
//const url = 'https://desa1.interside.cl/';
// const url = 'http://186.10.245.179/';
// const url = 'localhost/';
const urlbase = url + 'Portal-1.0.0/';
 //const urlbase = url;
const base = urlbase + 'api/';

export const RUTA_IMG = url + 'imagenes/';

export const DatosApi = {
  urlBaseApi: base,
  url: urlbase,
  httpOptions: {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  },
};
