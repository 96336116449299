import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = '';
    // const urlArray = ['/api/usuarios/valida', '/api/pais'];
    if (req.url.search('/oauth/token') > -1) {
      token = 'Basic c3JpLXBvcnRhbDoxMjM0NQ==';
    } else if (req.url.indexOf('/pais') > -1) {
      token = null;
    } else if (this.getToken()) {
      token = 'Bearer ' + this.getToken();
    }
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set(
          'Authorization', token
        )
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }

  public setToken(token, expiresin) {
    localStorage.setItem('token', token);
    localStorage.setItem('expires_at', expiresin);
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  public logOut() {
    localStorage.clear();
    sessionStorage.clear();
  }
}
