import {Injectable} from '@angular/core';
import {DatosApi} from 'src/app/services/datos_api';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Usuario} from 'src/app/models/usuario';
import {Observable, throwError} from 'rxjs/';
import {catchError} from 'rxjs/operators';
import {AuthInterceptorService} from '../auth-interceptor.service';
import {stringify} from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class CrudusuarioService {
  constructor(
    private http: HttpClient,
    private authinterceptorservice: AuthInterceptorService
  ) {
  }

  private urlEndPoint = DatosApi.urlBaseApi + 'usuarios';
  private urlEndPointBuscar = this.urlEndPoint + '/valida';
  private urlEndPointRecuperarContrasena = this.urlEndPoint + '/recuperar-contrasena';
  private urlEndPointReenviarCorreoConfirmacion = this.urlEndPoint + '/valida-email';
  private urlEndPointLogin = DatosApi.url + 'oauth/token';
  private urlActualizarDatosUsuario = this.urlEndPoint + 'update';
  private urlObtenerDatosUsuario = this.urlEndPoint + '/filtro';
  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  usuario: Usuario;


  crearUsuario(usuario: Usuario): Observable<any> {
    return this.http.post<any>(this.urlEndPoint, usuario, {headers: this.httpHeaders})
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );

  }

  login(usuario: Usuario): Observable<any> {
    const params = {
      username: usuario.email,
      password: usuario.contrasena,
      grant_type: 'password'
    };
    const body = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
    return this.http.post<any>(
      this.urlEndPointLogin.toString(),
      body,
      {headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }})
      .pipe(
        catchError(e => {
            return throwError(e);
          }
        )
      );
  }

  loginRRSS(usuario: Usuario): Observable<any> {
    return this.http.post<any>(`${this.urlEndPointBuscar}`, usuario, {headers: this.httpHeaders}).pipe(
      catchError(e => {
          return throwError(e);
        }
      )
    );
  }

  buscarUsuario(usuario: Usuario): Observable<any> {
    return this.http.post<any>(`${this.urlObtenerDatosUsuario}`, usuario, {headers: this.httpHeaders}).pipe(
      catchError(e => {
          return throwError(e);
        }
      )
    );
  }

  getUsuarioById(id): Observable<Usuario> {
    console.log(`${this.urlEndPoint}/${id}`);
    return this.http.get<Usuario>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  actualizarDatosUsuario(usuario: Usuario): Observable<any> {
    return this.http.put<any>(`${this.urlEndPoint}/` + usuario.id, usuario, {headers: this.httpHeaders}).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  getUsuario(): Usuario {
    return JSON.parse(window.sessionStorage.getItem('usuario'));
  }

  setUsuario(usuario: Usuario) {
    // this.usuario = usuario;
    window.sessionStorage.setItem('usuario', JSON.stringify(usuario));
  }

  reenviarCorreoConfirmacion(email: string): Observable<{ correoUsuario: string, mensaje: string }> {
    console.log(this.urlEndPointReenviarCorreoConfirmacion+"?correoUsuario="+email);
    return this.http.get<{ correoUsuario: string, mensaje: string }>(this.urlEndPointReenviarCorreoConfirmacion+"?correoUsuario="+email).pipe(
      catchError(e => {
        if (e.status === 0 || e.status === 500) {
          return throwError(e);
        }
        return throwError(e);
      })
    );
  }

  recuperarContrasena(email: string): Observable<string> {
    console.log(this.urlEndPointRecuperarContrasena+"?correoUsuario="+email);
    return this.http.get<string>(this.urlEndPointRecuperarContrasena+"?correoUsuario="+email).pipe(
      catchError(e => {
        if (e.status === 0 || e.status === 500) {
          return throwError(e);
        }
        return throwError(e);
      })
    );
  }
}
