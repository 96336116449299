import {
  Component,
  OnInit,
  Input,
  ɵSWITCH_ELEMENT_REF_FACTORY__POST_R3__
} from '@angular/core';
import { CrudcampanaService } from 'src/app/services/crud/crudcampana.service';
import Swal from 'sweetalert2';
import { Campana } from 'src/app/models/campana';
import { CrudespaciourbanoService } from 'src/app/services/crud/crudespaciourbano.service';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import { Router } from '@angular/router';
import { CrudarchivoService } from 'src/app/services/crud/crudarchivo.service';
import { CAPACIDAD_MAXIMA } from 'src/app/models/constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-nueva-campana',
  templateUrl: './nueva-campana.component.html',
  styleUrls: []
})
export class NuevaCampanaComponent implements OnInit {
  @Input() campana: Campana;
  @Input() espacios: EspacioUrbano[];
  frmData: FormData;
  myFiles: any;
  fechaAct: any;

  constructor(
    public crudCampana: CrudcampanaService,
    public crudEspacio: CrudespaciourbanoService,
    public router: Router,
    public crudArchivo: CrudarchivoService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    console.log('Inicio Nueva Campana');
    this.campana = new Campana();
  }

  // Inicia la variable donde se almacena la imagen
  cargarImagen(event) {
    if (event.length !== 0) {
      this.campana.publicidad = event[0].name.replace(/ /g, '');
      this.myFiles = event[0];
      this.frmData = new FormData();
      this.frmData.set('archivo', this.myFiles);
    } else {
      this.campana.publicidad = undefined;
    }
  }

  crear() {
    this.fechaAct = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    if (
      this.campana.nombre &&
      this.campana.espacio &&
      this.campana.publicidad &&
      this.campana.tiempo &&
      this.campana.formatFechaInicio &&
      this.campana.formatFechaFin
    ) {
      // Condiciones mínimas
      if (
        this.campana.nombre.length >= 4 &&
        this.campana.formatFechaInicio < this.campana.formatFechaFin &&
        this.myFiles.size <= CAPACIDAD_MAXIMA &&
        (this.myFiles.type.split('/')[0] === 'video' || this.myFiles.type.split('/')[0] === 'image') &&
        this.campana.tiempo >= 0 &&
        this.fechaAct <= this.campana.formatFechaInicio
      ) {
        this.subirImagen();
      }
      if (this.campana.nombre.length < 4) {
        Swal.fire('El nombre debe contener mínimo 4 caracteres');
      } else if (this.campana.formatFechaInicio > this.campana.formatFechaFin) {
        Swal.fire(
          'La fecha de fin no puede ser anterior a la fecha de inicio.'
        );
      } else if (this.campana.tiempo < 0) {
        Swal.fire('La duración no pueden ser números negativos');
      } else if (this.myFiles.size > CAPACIDAD_MAXIMA) {
        Swal.fire('El archivo es demasiado grande, debe pesar 3MB o menos.');
      } else if (this.myFiles.type.split('/')[0] !== 'video' && this.myFiles.type.split('/')[0] !== 'image') {
        Swal.fire('La publicidad debe ser un video o una imagen.');
      } else if (this.fechaAct > this.campana.formatFechaInicio) {
        Swal.fire('La campaña no puede empezar antes de la fecha actual.');
      }
    } else {
      Swal.fire('Debe llenar todos los campos');
    }
  }

  subirImagen() {
    Swal.fire({ title: 'Subiendo publicidad', onBeforeOpen: () => { Swal.showLoading(); } });
    this.crudArchivo.subir(this.frmData).subscribe(
      () => {
        this.ingresarCampana();
      },
      err => {
        if (err.status === 500 || err.status === 0) {
          if (err.error && err.error.mensaje) {
            Swal.fire(err.error.mensaje);
          } else {
            Swal.fire('No hay conexión con la Base de Datos');
          }
        } else {
          Swal.fire({
            title: 'No fue posible subir archivo',
            text: err.error.mensaje
          });
        }
      }
    );
  }

  ingresarCampana() {
    this.crudCampana.create(this.campana).subscribe(
      () => {
        Swal.fire('Campaña creada').then(() => window.location.reload());
      },
      // en el caso de que no se pueda crear el usuario se muestra el mensaje de error
      err => {
        this.borrarArchivo(this.campana.publicidad);
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);

        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  borrarArchivo(archivo: string) {
    this.crudArchivo.borrar(archivo).subscribe();
  }
}
