export const ESTADO_ACTIVO_ESPACIO_URBANO = 1;
export const ID_FACEBOOK = 1;
export const ID_GOOGLE = 2;
export const ID_TWITTER = 3;

// Constantes Facebook
export const FACEBOOK_APP_ID = '364452258607293';

// Constantes Google
export const GOOGLE_APP_ID = '232750150967-bstdcjklekv1bck5toqkh5fjakfa6ecs.apps.googleusercontent.com';

export const USUARIO_NO_ENCONTRADO = 404;
