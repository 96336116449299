import {Component, OnInit} from '@angular/core';
import {Usuario} from 'src/app/models/usuario';
import {CrudusuarioService} from 'src/app/services/crud/crudusuario.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {DatosApi} from 'src/app/services/datos_api';
import {ParamsService} from 'src/app/services/params.service';
import {Params} from 'src/app/models/params';
import {IMAGEN_DEFAULT} from 'src/app/models/constants';
import {RUTA_IMG} from 'src/app/services/datos_api';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthInterceptorService} from 'src/app/services/auth-interceptor.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: ['html{background: none}']
})
export class LoginComponent implements OnInit {
  // Se crea la variable donde se guardara el correo y la contraseña para luego ser validada en el back
  usuario: Usuario;
  // Se crea la variable donde se guardara la url donde esta el logo
  logoImagen: any;
  paramsResp: Params;
  rutaImg: string;
  visibilidad: Boolean;
  continuar: Boolean;
  contador: number;
  sonidoIcon: string;

  constructor(
    public crudusuarioService: CrudusuarioService,
    public router: Router,
    public paramsService: ParamsService,
    public sanitizer: DomSanitizer,
    private authinterceptorservice: AuthInterceptorService
  ) {
    this.usuario = new Usuario();
    this.paramsResp = new Params();
    this.rutaImg = RUTA_IMG;
    this.continuar = false;
    this.visibilidad = false;
    this.sonidoIcon = 'volume_off';
  }

  ngOnInit() {
    this.cargarParams();
  }

  cargarParams() {
    this.paramsResp = this.paramsService.getParams();
    this.cargarImagen();
  }

  cargarImagen() {
    // Su se encuentra una imagen en la sesión
    if (this.paramsResp !== null && this.paramsResp.imagenLogo) {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + this.paramsResp.imagenLogo
      );
      // Si no se encuentra imagen, se pone una por defecto
    } else {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + IMAGEN_DEFAULT
      );
    }
    // Envia al principio de la pagina, luego de cargar la imagen
    window.scroll(0, 0);
  }

  ingresar() {
    if (this.paramsResp) {
      this.usuario.mac = this.paramsResp.clientMac;
    }
    if (this.validarUsuario()) {
      this.usuario.email = this.usuario.email.toLowerCase();
      // Se ejecuta el metodo para buscar al usuario, se envia el correo y la contraseña del usuario
      this.crudusuarioService.login(this.usuario).subscribe(
        // Cuando se encuentra al usuario
        usuario => {
          if (usuario.user.email === 'daynet@interside.cl' || usuario.user.email === 'marketing-sri@espacio.com') {
            this.crudusuarioService.setUsuario(usuario.user);
            this.authinterceptorservice.setToken(usuario.access_token, usuario.expires_in);
            this.router.navigate(['administracion']);
          } else {
            this.crudusuarioService.setUsuario(usuario);
            this.authinterceptorservice.setToken(usuario.access_token, usuario.expires_in);
            // Se muestra la publicidad
            this.mostrarPublicidad();
          }
        },

        // En caso de haber errores
        err => {
          // console.error(err);
          // Si el usuario no se encuentra
          if (err.status === 404) {
            Swal.fire(`Correo o contraseña errónea`);
            // Errores no controlados desde el back
          } else if (err.status === 400) {
            if(err.error.error_description === 'User is disabled'){
              Swal.fire(`Correo sin validar`);
            }else{
              Swal.fire(`Correo o contraseña errónea`);
            }
          } else if (err.error && err.error.mensaje) {
            Swal.fire(err.error.mensaje);
          } else if (err.status === 500 || err.status === 0) {
            Swal.fire('No hay conexión con la Base de Datos');
          }
        }
      );
    }
  }

  validarUsuario(): boolean {
    // Validar que se haya ingresado contraseña y correo
    if (this.usuario.email && this.usuario.contrasena) {
      // Validar que el correo y la contraseña cumplan con las condiciones minimas
      if (this.validarCorreo() && this.usuario.contrasena.length >= 4) {
        return true;
      } else {
        if (!this.validarCorreo()) {
          Swal.fire('Debes ingresar un correo válido.');
        } else {
          Swal.fire('La contraseña debe contener como mínimo 4 caracteres');
        }
        return false;
      }
    } else {
      Swal.fire('Debes completar todos los campos');
      return false;
    }
  }

  mostrarPublicidad() {
    Swal.close();
    // Valida que se tengan los paramentros
    if (this.paramsResp) {
      // Si tiene tiempo, significa que tiene publicidad
      if (this.paramsResp.tiempoCamp) {
        this.visibilidad = true;
        this.contador = this.paramsResp.tiempoCamp;

        let intervalo = setInterval(
          function() {
            if (this.contador > 0) {
              this.contador--;
              intervalo;
            } else {
              this.continuar = true;
            }
          }.bind(this),
          // Si no tiene publicidad, se redirecciona
          1000
        );
      } else {
        window.location.href = this.paramsResp.urlAcceso;
      }
    } else {
      Swal.fire(
        'Ocurrió un problema al intentar darte acceso a internet, por favor, intentalo más tarde.'
      );
    }
  }

  redireccionar() {
    window.location.href = this.paramsResp.urlAcceso;
  }

  // Metodo para volver a la pagina anterior, es utilizado por el boton regresar
  back() {
    window.history.back();
  }

  validarCorreo(): boolean {
    const regex = '^[\\w-_\\.+]*[\\w-_\\.]\\@([\\w]+\\.)+[\\w]+[\\w]$';

    if (!this.usuario.email.match(regex)) {
      return false;
    } else {
      return true;
    }
  }

  reenviarCorreoConfirmacion(){
    Swal.fire({
      title: 'Reenviar correo de confirmación',
      input: 'email',
      cancelButtonText: 'Cancelar',
      inputPlaceholder: 'Ingresa tu correo electrónico.',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: 'Buscando usuario.', onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        this.crudusuarioService.reenviarCorreoConfirmacion(result.value).subscribe(
          rs => {
            console.log(rs);
            if(rs.correoUsuario === 'correo_valido'){
              Swal.fire(`El correo ${result.value} ya se encuentra validado`);
            }else{
              Swal.fire('Correo de confirmación enviado a: ' + result.value);
            }
          },
          err => {
            if (err.error && err.error.mensaje) {
              Swal.fire(err.error.mensaje);
            } else if (err.status === 500 || err.status === 0) {
              Swal.fire('No hay conexión con la Base de Datos');
            } else if (err.status === 404) {
              Swal.fire({
                title: 'Usuario no registrado.',
                showCancelButton: true,
                confirmButtonText: 'Registrarme',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (!result.dismiss) {
                  this.router.navigate(['registro']);
                }
              });
            }
          }
        );
      }
    });
  }

  recuperarContrasena() {
    Swal.fire({
      title: 'Recuperar Contraseña',
      input: 'email',
      cancelButtonText: 'Cancelar',
      inputPlaceholder: 'Ingresa tu correo electrónico.',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: 'Buscando usuario.', onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        this.crudusuarioService.recuperarContrasena(result.value).subscribe(
          rs => {
            Swal.fire('Contraseña enviada a: ' + result.value);
          },
          err => {
            if (err.error && err.error.mensaje) {
              Swal.fire(err.error.mensaje);
            } else if (err.status === 500 || err.status === 0) {
              Swal.fire('No hay conexión con la Base de Datos');
            } else if (err.status === 404) {
              Swal.fire({
                title: 'Usuario no registrado.',
                showCancelButton: true,
                confirmButtonText: 'Registrarme',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (!result.dismiss) {
                  this.router.navigate(['registro']);
                }
              });
            }
          }
        );
      }
    });
  }

  cambiarSonido() {
    let video = document.getElementById('myVideo') as HTMLMediaElement;

    if (video.muted) {
      video.muted = false;
      this.sonidoIcon = 'volume_up';
    } else {
      video.muted = true;
      this.sonidoIcon = 'volume_off';
    }
  }
}
