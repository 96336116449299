import { Injectable } from '@angular/core';
import { DatosApi } from 'src/app/services/datos_api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs/';
import { catchError, tap, map } from 'rxjs/operators';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';

@Injectable({
  providedIn: 'root'
})
export class CrudespaciourbanoService {

  private urlEndPoint = DatosApi.urlBaseApi + 'espacio';
  private urlEndPointIdent = this.urlEndPoint + '/filtro';
  private urlEndPointTodos = this.urlEndPoint + '/todos';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient
  ) { }

  getEspacioUrbano(id: number): Observable<any> {
    return this.http.get<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  getEspacioIdent(espacio: EspacioUrbano): Observable<any> {

    return this.http.post<any>(`${this.urlEndPointIdent}`, espacio, { headers: this.httpHeaders }).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  getAllEspacios() {
    return this.http.get<EspacioUrbano[]>(this.urlEndPointTodos).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  update(espacio: EspacioUrbano) {
    return this.http.post<any>(`${this.urlEndPoint}`, espacio, { headers: this.httpHeaders }).pipe(
      catchError(e => {
        return throwError(e);
      })
    );

  }

  getEspaciosPag(page: number): Observable<any> {

    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(
      map((response: any) => {
        (response.content as EspacioUrbano[]).map(espacio => {
          return espacio;
        });
        return response;
      })
    );
  }

  getEspaciosActivos() {
    return this.http.get<EspacioUrbano[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  create(espacio: EspacioUrbano): Observable<any> {
    return this.http.post<any>(this.urlEndPoint, espacio, { headers: this.httpHeaders })
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }

}
