import { Injectable } from '@angular/core';
import { Params } from '../models/params';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {
 params: Params
  constructor() { }

  getParams(): Params {
    return JSON.parse(window.sessionStorage.getItem('params'));
  }

  setParams(params: Params) {
    // this.usuario = usuario;
    window.sessionStorage.setItem('params', JSON.stringify(params));
  }


}
