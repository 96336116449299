import {Injectable} from '@angular/core';
import { min } from 'moment';
import phone from 'phone';
import { Pais } from 'src/app/models/localidad/pais';
import {NO_ENCONTRADO} from '../../pages/registro/registro.constants';

@Injectable({
  providedIn: 'root'
})
export class ValidacionesService {

  constructor() {
  }

  /*public validarTelefono(telef: number): boolean {
    return telef.toString().indexOf('00000000') === NO_ENCONTRADO &&
      telef.toString().indexOf('11111111') === NO_ENCONTRADO &&
      telef.toString().indexOf('22222222') === NO_ENCONTRADO &&
      telef.toString().indexOf('33333333') === NO_ENCONTRADO &&
      telef.toString().indexOf('44444444') === NO_ENCONTRADO &&
      telef.toString().indexOf('55555555') === NO_ENCONTRADO &&
      telef.toString().indexOf('66666666') === NO_ENCONTRADO &&
      telef.toString().indexOf('77777777') === NO_ENCONTRADO &&
      telef.toString().indexOf('88888888') === NO_ENCONTRADO &&
      telef.toString().indexOf('99999999') === NO_ENCONTRADO &&
      telef.toString().indexOf('12345678') === NO_ENCONTRADO &&
      telef.toString().indexOf('01234567') === NO_ENCONTRADO &&
      telef.toString().indexOf('98765432') === NO_ENCONTRADO;
  }*/

  validarTelefonoV2(pais: Pais, numero: string|number): boolean {
    return phone('+' + pais.codigo + numero, {strictDetection: true}).isValid;
  }

  obtenerNumero(pais: Pais, numero: string|number): string {
    const telefono = phone('+' + pais.codigo + numero, {strictDetection: true});
    if(telefono.isValid)
      return telefono.phoneNumber
    return undefined
  }
}
