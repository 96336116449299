import {Injectable} from '@angular/core';
// import {Pais} from '../models/localidad/pais';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs/';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatosApi} from 'src/app/services/datos_api';
import {Provincia} from '../models/localidad/provincia';
import {Comuna} from '../models/localidad/comuna';

@Injectable({
  providedIn: 'root'
})
export class LocalidadService {

  private urlEndPoint = DatosApi.urlBaseApi + 'pais';
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient
  ) {
  }

  getLocalidad(localidad: string): Observable<any[]> {
    return this.http.get<any[]>(DatosApi.urlBaseApi + localidad).pipe(
      catchError(e => {
        if (e.status === 0 || e.status === 500) {
          return throwError(e);
        }
        return throwError(e);
      })
    );
  }

  postProvincia(idRegion: number): Observable<Provincia[]> {
    return this.http.post<Provincia[]>(DatosApi.urlBaseApi + 'provincia/filtro/', {idRegion: idRegion})
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }

  postComuna(idProvincia: number): Observable<Comuna[]> {
    return this.http.post<Comuna[]>(DatosApi.urlBaseApi + 'comuna/filtro/', {idProvincia: idProvincia})
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }

}
