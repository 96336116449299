import { Injectable } from '@angular/core';
import { catchError, tap, map} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs/';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatosApi } from 'src/app/services/datos_api';
import { Campana } from 'src/app/models/campana';

@Injectable({
  providedIn: 'root'
})
export class CrudcampanaService {

  private urlEndPoint = DatosApi.urlBaseApi + 'campana';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  private urlEndPointBuscar = this.urlEndPoint + '/filtro'
  constructor(
    private http: HttpClient
  ) { }

  create(campana: Campana): Observable<any>{
    return this.http.post<any>(this.urlEndPoint, campana, { headers: this.httpHeaders })
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }
  
  getAll():Observable<any>{
    return this.http.get(this.urlEndPoint).pipe(
      map((response: any) => {
        (response.content as Campana[]);
        return response;
      })
    );
  }

  update(campanaUp : Campana){
    return this.http.post<any>(`${this.urlEndPoint}`, campanaUp, { headers: this.httpHeaders }).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  getCampanasPag(page: number): Observable<any> {

    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(
      map((response: any) => {
        (response.content as Campana[]).map(campana => {

          return campana;
        });
        return response;
      })
    ); 
    
  }

  getCampanaFiltro(campana : Campana){
    return this.http.post<any>(`${this.urlEndPointBuscar}`, campana, {headers: this.httpHeaders}).pipe(
      catchError(e => {
          return throwError(e);
        }
      )
    );
  }

  delete(id : number){
    return this.http.delete<Campana>(`${this.urlEndPoint}/${id}`, { headers: this.httpHeaders }).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

}
