import { Component, OnInit } from '@angular/core';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import { CrudespaciourbanoService } from 'src/app/services/crud/crudespaciourbano.service';
import Swal from 'sweetalert2';
import { CrudarchivoService } from 'src/app/services/crud/crudarchivo.service';
import { CAPACIDAD_MAXIMA } from 'src/app/models/constants';
import { RUTA_IMG } from 'src/app/services/datos_api';

@Component({
  selector: 'app-lista-logos',
  templateUrl: './lista-logos.component.html',
  styleUrls: []
})
export class ListaLogosComponent implements OnInit {
  espacios: EspacioUrbano[];
  paginador: any;
  frmData: FormData;
  myFiles: string;
  pagAct: number;

  constructor(
    public crudEspacio: CrudespaciourbanoService,
    public crudArchivo: CrudarchivoService
  ) {}

  ngOnInit() {
    this.cargarEspacios(0);
  }

  cargarEspacios(page: number): void {
    this.crudEspacio.getEspaciosPag(page).subscribe(response => {
      this.espacios = response.content as EspacioUrbano[];
      this.paginador = response;
    });

    this.pagAct = page;
  }

  mostrarImagen(imagen: string) {
    Swal.fire({
      imageUrl: RUTA_IMG + imagen,
      imageAlt: imagen
    });
  }

  cambiarEstado({ ...espacioCam }: EspacioUrbano, est: number) {
    espacioCam.status = est;
    this.actualizar(espacioCam, null);
  }

  nuevaImagen({ ...espacioIm }: EspacioUrbano) {
    const imagenAntigua = espacioIm.imagen;
    Swal.fire({
      input: 'file',
      imageAlt: 'Nueva imagen',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      inputAttributes: { 'accept': 'image/*' }
    }).then(result => {
      if (!result.dismiss) {
        if (result.value !== null) {
          //Cargar nueva imagen
          if (result.value.name.replace(/ /g, '') !== espacioIm.imagen) {
            Swal.fire({ title: 'Subiendo imagen', onBeforeOpen: () => {Swal.showLoading();}});
            espacioIm.imagen = result.value.name.replace(/ /g, '');
            this.myFiles = result.value;
            this.frmData = new FormData();
            this.frmData.set('archivo', this.myFiles);

            if (result.value.size > CAPACIDAD_MAXIMA) {
              Swal.fire(
                'El archivo es demasiado grande, debe pesar 3MB o menos.'
              );
            } else if (result.value.type.split('/')[0] === 'image') {
              this.subirImagen(espacioIm, this.frmData, imagenAntigua);
            } else {
              Swal.fire('Solo puedes subir imagenes.');
            }
          } else {
            Swal.fire('El nombre de la nueva imagen no puede ser el mismo.');
          }
        } else {
          Swal.fire('Debe ingresar un nuevo logo.');
        }
      }
    });
  }

  nuevoNombre({ ...espacioActNom }: EspacioUrbano) {
    Swal.fire({
      title: 'Nombre actual: ' + espacioActNom.nombre,
      text: 'Ingrese el nuevo nombre: ',
      input: 'text',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar'
    }).then(result => {
      // Cuando se presiona el botón aceptar entra aqui
      if (!result.dismiss) {
        // Validar que se haya ingresado un nombre
        if (result.value !== '') {
          // Condiciones minimas para el nuevo nombre
          if (result.value.length > 3) {
            // Validar que el nuevo nombre no sea el mismo que ya tenia
            if (espacioActNom.nombre !== result.value) {
              // Se cambia nombre del espacio urbano y se actualiza
              espacioActNom.nombre = result.value;
              this.actualizar(espacioActNom, null);
            } else {
              Swal.fire('No puedes ingresar el mismo nombre.');
            }
          } else {
            Swal.fire('El nombre debe tener al menos 4 caracteres.');
          }
        } else {
          Swal.fire('Debes ingresar un nombre.');
        }
      }
    });
  }

  subirImagen(espacioAct: EspacioUrbano, nuevoArchivo, archAntiguo: string) {
    this.crudArchivo.subir(nuevoArchivo).subscribe(
      data => {
        // Si se subio, se actualizará la campaña
        this.actualizar(espacioAct, archAntiguo);
      },
      err => {
        // En caso de que no se pueda subir el archivo
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);
        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  actualizar(espacioImAct: EspacioUrbano, imgAntigua: string) {
    this.crudEspacio.update(espacioImAct).subscribe(
      () => {
        this.cargarEspacios(this.pagAct);
        Swal.fire('Espacio Urbano actualizado');
        // Se borra la imagen antigua
        if (imgAntigua !== null) {
          this.borrarArchivo(imgAntigua);
        }
      },
      err => {
        // Se borra la nueva imagen que se queria subir
        if (imgAntigua !== null) {
          this.borrarArchivo(espacioImAct.imagen);
        }

        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);
        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  borrarArchivo(archivo: string) {
    this.crudArchivo.borrar(archivo).subscribe();
  }
}
