import { Component, OnInit, Output } from '@angular/core';
import { Campana } from 'src/app/models/campana';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import { PuntoAcceso } from 'src/app/models/punto-acceso';
import { CrudusuarioService } from 'src/app/services/crud/crudusuario.service';
import { Usuario } from 'src/app/models/usuario';
import { CrudespaciourbanoService } from 'src/app/services/crud/crudespaciourbano.service';
import {AuthInterceptorService} from '../../services/auth-interceptor.service';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styles: []
})
export class AdministracionComponent implements OnInit {
  imgEspacio: any;
  campana: Campana;
  espacio: EspacioUrbano;
  punto: PuntoAcceso;
  usuarioAdm: Usuario;
  espacios: EspacioUrbano[];
  adminTotal: boolean = false;

  constructor(
    public crudUsuario: CrudusuarioService,
    public crudEspacio: CrudespaciourbanoService,
    private authService: AuthInterceptorService
    ) {}

  ngOnInit() {
    // Quita la imagen de fondo
    document.documentElement.style.backgroundImage = 'none';
    this.validarUsuario();
  }

  validarUsuario() {
    if (
      // Validar que el usuario sea administrador
      this.crudUsuario.getUsuario() === null ||
      !(this.crudUsuario.getUsuario().email === 'daynet@interside.cl' ||
      this.crudUsuario.getUsuario().email === 'marketing-sri@espacio.com')
    ) {
        window.location.href = '';
    } else {
      this.usuarioAdm = this.crudUsuario.getUsuario();
      if(this.crudUsuario.getUsuario().email === 'daynet@interside.cl'){
        this.adminTotal = true;
      }

    }
  }

  cambioTap(event) {
    if (event === 1) {
      this.espacio = new EspacioUrbano();
    }
    if (event === 3) {
      this.campana = new Campana();
    }
    if (event === 5) {
      this.punto = new PuntoAcceso();
    }
    if(event === 3 || event === 5 ){
      this.cargarEspacios();
    }
  }

  cargarEspacios() {
    this.crudEspacio
      .getAllEspacios()
      .subscribe(espacios => (this.espacios = espacios));
  }

  logout() {
    this.authService.logOut();
    window.location.href = '';
  }
}
