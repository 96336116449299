import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { CrudusuarioService } from 'src/app/services/crud/crudusuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-validar-correo',
  templateUrl: './validar-correo.component.html',
  styleUrls: []
})
export class ValidarCorreoComponent implements OnInit {
  usuario: Usuario;
  fechaCorreo: Date;

  constructor(
    public route: ActivatedRoute,
    public crudusuarioService: CrudusuarioService
  ) {
    this.usuario = new Usuario();
  }

  ngOnInit() {
    this.generarToken();
    this.obtenerUSuario();
  }

  obtenerUSuario() {
    this.route.queryParams.subscribe(params => {
      this.usuario.id = params['id'];
      this.fechaCorreo = params['fecha'];
    });
    let fechaAct = new Date();
    if (fechaAct === this.fechaCorreo) {
      this.crudusuarioService.getUsuarioById(this.usuario.id).subscribe(response => {
        this.usuario = response;
        this.cambiarEstadoCorreo();
      });
    } else {
      Swal.fire('El correo expiró, vuelve a enviar el correo.').then(() => {
        this.enviarCorreo();
      });
    }
  }
  cambiarEstadoCorreo() {
    // this.usuario.estadoCorreo = 1;
    this.crudusuarioService
      .actualizarDatosUsuario(this.usuario)
      .subscribe(response => {
        Swal.fire(
          'El correo ' + response.usuario.email + ' ha sido validado con éxito'
        ).then(() => {
          window.location.href = 'http://www.espaciourbano.cl';
        });
        // la proxima vez que inicies sesión tendrás acceso ilimitado a internet
      });
  }

  generarToken() {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    console.log(result);
  }

  enviarCorreo() {}
}
