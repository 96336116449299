export class Usuario {
  // Editables
  nombre: string;
  apellido: string;
  rut: number;
  dvrut: string;
  telefono: number;
  contrasena: string;
  genero: string;
  fechaNacimiento: string;
  formatFechaNacimiento: string;
  idPaisTelefono: number;
  idComuna: number;
  direccion: string;
  token: string;

  // No editables
  id: number;
  email: string;
  nodo: string;
  mac: string;
  idRedSocial: number;
  fechaCreacion: string;
  hijos: number;
}
