import { Component, OnInit } from '@angular/core';
import { CrudcampanaService } from 'src/app/services/crud/crudcampana.service';
import { Campana } from 'src/app/models/campana';
import Swal from 'sweetalert2';
import { CrudarchivoService } from 'src/app/services/crud/crudarchivo.service';
import { CAPACIDAD_MAXIMA } from 'src/app/models/constants';
import { RUTA_IMG } from 'src/app/services/datos_api';

@Component({
  selector: 'app-lista-campanas',
  templateUrl: './lista-campanas.component.html',
  styleUrls: []
})
export class ListaCampanasComponent implements OnInit {
  campanas: Campana[];
  paginador: any;
  url: string;
  frmData: FormData;
  myFiles: string;
  archivoAntiguo: string;
  pagActual: number;
  visibilidad: boolean;
  rutaCampana: string;
  tipoCampana: number;

  constructor(
    public crudCampana: CrudcampanaService,
    public crudArchivo: CrudarchivoService
  ) { }

  ngOnInit() {
    this.cargarCampanas(0);
  }

  cargarCampanas(page: number): void {
    this.pagActual = page;
    this.crudCampana.getCampanasPag(page).subscribe(response => {
      this.campanas = response.content as Campana[];
      this.paginador = response;
    });
  }

  mostrarArchivo(imagen: string) {
    this.tipoCampana = this.getTipoCampana(imagen);
    this.rutaCampana = RUTA_IMG + imagen;
    this.visibilidad = true;

  }

  getTipoCampana(campana: string): number {
    const extension = campana.substr(campana.lastIndexOf('.') + 1).toLowerCase();
    if (extension === 'mp4' || extension === 'web' || extension === 'mov' || extension === 'vm4') {
      // 1 ES PARA VIDEOS
      return 1;
    }
    // 2 ES PARA IMAGENES
    return 2;
  }

  nuevoArchivo({ ...campanaIm }: Campana) {
    this.archivoAntiguo = campanaIm.publicidad;

    Swal.fire({
      input: 'file',
      imageAlt: 'Nueva publicidad',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      inputAttributes: { accept: 'video/*,image/*' }
    }).then(result => {
      // Validar que se haya elegido un archivo
      if (!result.dismiss) {
        if (result.value !== null) {
          if (result.value.name.replace(/ /g, '') != campanaIm.publicidad) {
            Swal.fire({
              title: 'Subiendo publicidad',
              onBeforeOpen: () => {
                Swal.showLoading();
              }
            });
            //  Cargar nueva publicidad
            campanaIm.publicidad = result.value.name.replace(/ /g, '');
            this.myFiles = result.value;
            this.frmData = new FormData();
            this.frmData.set('archivo', this.myFiles);

            //Se sube la nueva imagen
            if (result.value.size > CAPACIDAD_MAXIMA) {
              Swal.fire(
                'El archivo es demasiado grande, debe pesar 3MB o menos.'
              );
            } else if (result.value.type.split('/')[0] === 'video' || result.value.type.split('/')[0] === 'image') {
              this.subirAchivo(campanaIm, this.frmData, this.archivoAntiguo);
            } else {
              Swal.fire('La publicidad debe ser un video o una imagen.');
            }
          } else {
            Swal.fire('La nueva publicidad no puede tener el mismo nombre');
          }
          //  Si viene vacio, lanza error
        } else if (result.value === null) {
          Swal.fire('Debe ingresar una publicidad.');
        }
      }
    });
  }

  subirAchivo(campanaParaAct: Campana, nuevoArchivo, archAntiguo: string) {
    this.crudArchivo.subir(nuevoArchivo).subscribe(
      () => {
        // Si se subio, se actualizará la campaña
        this.actualizarCampana(campanaParaAct, archAntiguo);
      },
      err => {
        // En caso de que no se pueda subir el archivo
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);
        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('Error al intentar subir la imagen');
        }
      }
    );
  }

  actualizarCampana({ ...campanaAct }: Campana, archAntiguo: string) {
    this.crudCampana.update(campanaAct).subscribe(
      result => {
        this.cargarCampanas(this.pagActual);
        // Si se actualiza, se actualiza la lista y se borra el archivo antiguo
        if (archAntiguo !== null) {
          this.borrarArchivo(archAntiguo);
        }
        Swal.fire('Campaña actualizada');
      },
      // Si no se pudo actualizar
      err => {
        if (archAntiguo !== null) {
          this.borrarArchivo(campanaAct.publicidad);
        }
        if (err.error.mensaje) {
          Swal.fire(err.error.mensaje);
        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  borrarArchivo(archivo: string) {
    this.crudArchivo.borrar(archivo).subscribe(() => '');
  }

  nuevoNombre({ ...campanaActNom }: Campana) {
    Swal.fire({
      title: 'Nombre actual: ' + campanaActNom.nombre,
      text: 'Ingrese el nuevo nombre: ',
      input: 'text',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar'
    }).then(result => {
      if (!result.dismiss) {
        if (result.value !== '') {
          if (result.value.length > 3) {
            if (campanaActNom.nombre !== result.value) {
              campanaActNom.nombre = result.value;
              this.actualizarCampana(campanaActNom, null);
            } else {
              Swal.fire('No puedes ingresar el mismo nombre.');
            }
          } else {
            Swal.fire('El nombre debe tener al menos 4 caracteres.');
          }
        } else {
          Swal.fire('Debes ingresar un nombre.');
        }
      }
    });
  }

  nuevoTiempo({ ...campanaActTiemp }: Campana) {
    Swal.fire({
      title: 'Tiempo actual: ' + campanaActTiemp.tiempo,
      text: 'Ingrese el nuevo tiempo: ',
      input: 'number',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar'
    }).then(result => {
      if (!result.dismiss) {
        if (result.value !== '') {
          if (result.value >= 0) {
            if (campanaActTiemp.tiempo !== parseInt(result.value)) {
              campanaActTiemp.tiempo = result.value;
              this.actualizarCampana(campanaActTiemp, null);
            } else {
              Swal.fire('No puedes ingresar el mismo tiempo.');
            }
          } else {
            Swal.fire('El tiempo no puede ser negativo.');
          }
        } else {
          Swal.fire('Debes ingresar un tiempo.');
        }
      }
    });
  }

  eliminarCampana(campanaDel: Campana) {
    Swal.fire({
      title: '¿Estás seguro que quieres eliminar ' + campanaDel.nombre + ' ?',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value === true) {
        this.crudCampana.delete(campanaDel.id).subscribe(
          () => {
            this.campanas = this.campanas.filter(camp => camp !== campanaDel);
            Swal.fire('Campaña eliminada');
            this.borrarArchivo(campanaDel.publicidad);
          },
          err => {
            if (err.error && err.error.mensaje) {
              Swal.fire(err.error.mensaje);
            } else if (err.status === 500 || err.status === 0) {
              Swal.fire('No hay conexión con la Base de Datos');
            }
          }
        );
      }
    });
  }

  cambiarStatus({ ...campana }: Campana) {
    campana.status = campana.status ? 0 : 1;
    this.actualizarCampana(campana, null);
  }

  getDescripcionStatus(status: number): string {
    switch (status) {
      case 0: return 'Inactiva';
      case 1: return 'Activa';
      default: return 'Desconocido';
    }
  }

}
