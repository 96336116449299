import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CrudespaciourbanoService } from 'src/app/services/crud/crudespaciourbano.service';
import { CrudarchivoService } from 'src/app/services/crud/crudarchivo.service';
import { CAPACIDAD_MAXIMA } from 'src/app/models/constants';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevo-logo',
  templateUrl: './nuevo-logo.component.html',
  styleUrls: []
})
export class NuevoLogoComponent implements OnInit {
  @Input() imgEspacio: any;
  @Input() espacio: EspacioUrbano;
  espacios: EspacioUrbano[];
  frmData: FormData;
  myFiles: any;

  constructor(
    public crudEspacio: CrudespaciourbanoService,
    public crudArchivo: CrudarchivoService,
    public router: Router
  ) {}

  ngOnInit() {
    this.espacio = new EspacioUrbano();
  }

  cargarImagen(event: File) {
    this.espacio.imagen = event[0].name.replace(/ /g, '');
    this.myFiles = event[0];
    if (this.espacio.imagen != null) {
      this.frmData = new FormData();
      this.frmData.set('archivo', this.myFiles);
    } else Swal.fire('Campos Obligatorios!', 'Debe cargar archivo');
  }

  crear() {
    
    if (
      this.espacio.nombre &&
      this.espacio.identificador &&
      this.espacio.imagen
    ) {
      this.espacio.identificador = this.espacio.identificador.trim();
      if (
        this.espacio.nombre.length >= 4 &&
        this.espacio.identificador.length >= 4 &&
        this.myFiles.size <= CAPACIDAD_MAXIMA &&
        this.myFiles.type.split('/')[0] === 'image'
      ) {
        this.validarIndent();
        // this.subirImagen();
      } else {
        if (this.espacio.nombre.length < 4) {
          Swal.fire('El nombre debe tener al menos 4 caracteres.');
        }
        if (this.espacio.identificador.length < 4) {
          Swal.fire('El identificador debe tener al menos 4 caracteres.');
        }
        if (this.myFiles.size > CAPACIDAD_MAXIMA) {
          Swal.fire('El archivo es demasiado grande, debe pesar 3MB o menos.');
        }
        if (this.myFiles.type.split('/')[0] !== 'image') {
          Swal.fire('El logo debe ser una imagen.');
        }
      }
    } else {
      Swal.fire('Debes completar todos los campos');
    }
  }
  
  validarIndent(){
    let espacioIdent = new EspacioUrbano();
    espacioIdent.status = undefined;
    espacioIdent.identificador = this.espacio.identificador;
    this.crudEspacio.getEspacioIdent(espacioIdent).subscribe(
      result =>{
        if(result.length === 1){
          Swal.fire('El identificador ingresado ya existe en la base de datos.');
        } else {
          this.subirImagen();
        }
      }
    )
  }

  subirImagen() {
    Swal.fire({ title: 'Subiendo imagen', onBeforeOpen: () => { Swal.showLoading(); }});
    this.crudArchivo.subir(this.frmData).subscribe(
      () => {
        this.crearEspacio();
      },
      err => {
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);

        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  crearEspacio() {
    this.crudEspacio.create(this.espacio).subscribe(
      () => {
        Swal.fire('Espacio Urbano creado').then(() => window.location.reload());
      },
      //en el caso de que no se pueda crear se muestra el mensaje de error
      err => {
        this.borrarArchivo(this.espacio.imagen);
        
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);

        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  borrarArchivo(archivo: string) {
    this.crudArchivo.borrar(archivo).subscribe();
  }
}
