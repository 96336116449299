import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(
    private http: HttpClient
  ) {
  }

  getTerminosYCondiciones() {
    return this.http.get('assets/terminosYCondiciones.json')
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
  }
}
