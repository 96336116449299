import {EspacioUrbano} from './espacio-urbano'

export class Campana {
  id: number;
  publicidad: string;
  formatFechaInicio: Date;
  formatFechaFin: Date;
  tiempo: number;
  status: number = 0;
  espacio: EspacioUrbano;
  nombre: string;
  origenVideo: number = 1;
}
