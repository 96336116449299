import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { DatosApi } from 'src/app/services/datos_api';

@Injectable({
  providedIn: 'root'
})
export class CrudarchivoService {

  private urlEndPoint = DatosApi.urlBaseApi + 'archivo';

  constructor(private http: HttpClient, private router: Router) { }

  subir(frmData: FormData): Observable<String> {

    return this.http.post(this.urlEndPoint + '/subir', frmData)
      .pipe(
        map((response: any) => response.mensaje)
      );
  }

  borrar(archivo: String): Observable<String> {

    return this.http.delete(this.urlEndPoint + '/' + archivo)
      .pipe(
        map((response: any) => response.mensaje)
      );
  }


}
