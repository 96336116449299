import {Injectable} from '@angular/core';
import {DatosApi} from 'src/app/services/datos_api';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs/';
import {catchError, tap, map} from 'rxjs/operators';
import {EspacioUrbano} from 'src/app/models/espacio-urbano';
import { PuntoAcceso } from 'src/app/models/punto-acceso';

@Injectable({
  providedIn: 'root'
})
export class CrudpuntoAccesoService {

  private urlEndPoint = DatosApi.urlBaseApi + 'puntoAcceso';
  private urlEndPointFiltro = DatosApi.urlBaseApi + 'puntoAcceso/filtro';
  private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient
  ) {
  }

  buscarPuntoActivo(espacio: EspacioUrbano) {
    return this.http.post<any>(`${this.urlEndPointFiltro}`, espacio, {headers: this.httpHeaders}).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  crearPunto(puntoAcceso:PuntoAcceso){
    return this.http.post<any>(this.urlEndPoint, puntoAcceso, {headers: this.httpHeaders})
      .pipe(
        catchError(e => {
          return throwError(e);
        })
      );
 
  }

  buscarPuntoFiltro(punto :PuntoAcceso){
    return this.http.post<PuntoAcceso[]>(`${this.urlEndPointFiltro}`, punto, {headers: this.httpHeaders}).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }

  getPuntosPag(page: number) : Observable<any>{
    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(
      map( (response: any) => {
        return response;
      })
    ); 
  }

  actualizarPunto(puntoAct : PuntoAcceso): Observable<any> {
    return this.http.post<any>(`${this.urlEndPoint}`, puntoAct, {headers: this.httpHeaders}).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
    
  }

  delete(id: number){
    return this.http.delete<EspacioUrbano>(`${this.urlEndPoint}/${id}`, { headers: this.httpHeaders }).pipe(
      catchError(e => {
        return throwError(e);
      })
    );
  }


}
