import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {CrudusuarioService} from 'src/app/services/crud/crudusuario.service';
import {LocalidadService} from 'src/app/services/localidad.service';
import {Usuario} from 'src/app/models/usuario';
import {Pais} from 'src/app/models/localidad/pais';
import {Region} from 'src/app/models/localidad/region';
import {Comuna} from 'src/app/models/localidad/comuna';
import {Provincia} from 'src/app/models/localidad/provincia';
import {Router} from '@angular/router';
import { Params } from 'src/app/models/params';
import { ParamsService } from 'src/app/services/params.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RUTA_IMG } from 'src/app/services/datos_api';
import { IMAGEN_DEFAULT } from 'src/app/models/constants';
import {ValidacionesService} from '../../services/util/validaciones.service';
import {CANTIDAD_CARACTERES_NUMERO, VALIDA_CONTRASENA, VALIDA_TELEFONO, URL_ESPACIO_URBANO} from './actualizar-datos.constant';
import * as moment from 'moment';
import {AuthInterceptorService} from '../../services/auth-interceptor.service';

@Component({
  selector: 'app-actualizar-datos',
  templateUrl: './actualizar-datos.component.html',
  styleUrls: ['./actualizar-datos.component.css']
})
export class ActualizarDatosComponent implements OnInit {
  usuario: Usuario;
  pais: Pais;
  paises: Pais[];
  region: Region;
  regiones: Region[];
  comunas: Comuna[];
  provincias: Provincia[];
  rut: string;
  logoImagen: any;
  habilitaLocalidad: boolean;
  CODIGO_PAIS_CHILE = '41';
  habilitaEdicion: boolean;
  paramsResp: Params;
  contrasena2: string;
  muestraSelectorFecha: boolean;

  constructor(
    public crudusuarioService: CrudusuarioService,
    public localidadService: LocalidadService,
    public router: Router,
    public paramsService: ParamsService,
    public sanitizer: DomSanitizer,
    private validacionesService: ValidacionesService,
    private authService: AuthInterceptorService
  ) {
    this.usuario = new Usuario();
    this.ObtenerDatosUsuario(this.buscarUsuario());
  }

  ngOnInit() {
    this.cargarParams();
    this.cargarPaises();
    this.habilitaEdicion = false;
  }

  cargarParams(){
    this.paramsResp = this.paramsService.getParams();
    this.cargarImagen();
  }

  cargarImagen() {
    // Su se encuentra una imagen en la sesión
    if (this.paramsResp !== null && this.paramsResp.imagenLogo) {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + this.paramsResp.imagenLogo
      );
    // Si no se encuentra imagen, se pone una por defecto
    } else {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + IMAGEN_DEFAULT
      );
    }
    // Envia al principio de la pagina, luego de cargar la imagen
    window.scroll(0, 0);
  }

  buscarUsuario() {
    return this.crudusuarioService.getUsuario();
  }

  cargarPaises() {
    this.localidadService.getLocalidad('pais')
      .subscribe((paises) => {
        this.paises = paises;
      });
    this.usuario.idPaisTelefono = 41;
  }

  actualizarDatos() {

    if (this.Rut(this.usuario.rut + this.usuario.dvrut)) {
      this.crudusuarioService.actualizarDatosUsuario(this.usuario).subscribe(
        response => {
          Swal.fire({
            title: 'Genial!',
            text: 'Sus datos han sido actualizados exitosamente!',
            type: 'success',
            confirmButtonText: 'Aceptar'
          }).then(result => {
            this.crudusuarioService.setUsuario(this.usuario);
            this.redireccionEspacioUrbano();
          });
        },
        err => {
          Swal.fire({
            title: 'Alerta!',
            text: 'Ha ocurrido un error al actualizar tus datos!',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Salir de edición',
            confirmButtonText: 'Reintentar?'
          }).then(result => {
            if (!Swal.DismissReason.cancel) {
              this.actualizarDatos();
            } else {
              this.logOut();
            }
          });
        }
      );
    } else {
      Swal.fire('El rut ingresado no es válido');
    }
  }

  cancelarActualizacion() {
    Swal.fire({
      title: 'Actualizar Datos',
      text: 'Está seguro que quiere cancelar la actualización?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Salir de edición',
      confirmButtonText: 'Volver a edición'
    }).then(
      result => {
        result.dismiss === Swal.DismissReason.cancel ? this.logOut() : null;
      });
  }

  validarRut() {
    this.habilitaEdicion = !this.Rut(this.usuario.rut + this.usuario.dvrut);
  }

  cargaRegion(idPais: any) {
    if (idPais === this.CODIGO_PAIS_CHILE) {
      this.habilitaLocalidad = true;
      this.localidadService.getLocalidad('region')
        .subscribe(regiones => (this.regiones = regiones));
    } else {
      this.habilitaLocalidad = false;
    }
  }

  cargaProvincia(idRegion: number) {
    this.localidadService.postProvincia(idRegion)
      .subscribe(provincias => (this.provincias = provincias));
  }

  cargarComuna(idProvincia: number) {
    this.localidadService.postComuna(idProvincia)
      .subscribe(comunas => (this.comunas = comunas));
  }

  /***Métodos para validar run*/
  revisarDigito(dvr: string) {
    let dv = dvr + '';
    if (
      dv != '0' &&
      dv != '1' &&
      dv != '2' &&
      dv != '3' &&
      dv != '4' &&
      dv != '5' &&
      dv != '6' &&
      dv != '7' &&
      dv != '8' &&
      dv != '9' &&
      dv != 'k' &&
      dv != 'K'
    ) {
      // alert("Debe ingresar un digito verificador valido");
      return false;
    }
    return true;
  }

  revisarDigito2(crut: string) {
    let largo = crut.length;
    let rut;
    if (largo < 2) {
      // alert("Debe ingresar el rut completo");
      return false;
    }
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    let dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);

    if (rut == null || dv == null) return 0;

    var dvr = '0';
    let suma = 0;
    let mul = 2;
    let i;
    for (i = rut.length - 1; i >= 0; i--) {
      suma = suma + rut.charAt(i) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    let res = suma % 11;
    if (res == 1) dvr = 'k';
    else if (res == 0) dvr = '0';
    else {
      let dvi = 11 - res;
      dvr = dvi + '';
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }

    return true;
  }

  Rut(texto: string) {
    var tmpstr = '';
    let i;
    for (i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != ' ' &&
        texto.charAt(i) != '.' &&
        texto.charAt(i) != '-'
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    let largo = texto.length;

    if (largo < 2) {
      // alert("Debe ingresar el rut completo");
      return false;
    }

    for (i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != '0' &&
        texto.charAt(i) != '1' &&
        texto.charAt(i) != '2' &&
        texto.charAt(i) != '3' &&
        texto.charAt(i) != '4' &&
        texto.charAt(i) != '5' &&
        texto.charAt(i) != '6' &&
        texto.charAt(i) != '7' &&
        texto.charAt(i) != '8' &&
        texto.charAt(i) != '9' &&
        texto.charAt(i) != 'k' &&
        texto.charAt(i) != 'K'
      ) {
        // alert("El valor ingresado no corresponde a un R.U.T valido");
        return false;
      }
    }

    let j;

    var invertido = '';
    for (i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = '';
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + '-';
    let cnt = 0;

    for (i = 1, j = 2; i < largo; i++, j++) {
      //alert('i=[' + i + '] j=[' + j +']' );
      if (cnt == 3) {
        dtexto = dtexto + '.';
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }

    invertido = '';
    for (i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    let rut = invertido.toUpperCase();

    if (this.revisarDigito2(texto)) return true;

    return false;
  }

  validaFono(idPais, fono: number) {
    return this.validacionesService.validarTelefonoV2(this.getPaisById(idPais),fono) ?
      Swal.fire(VALIDA_TELEFONO.TITULO, VALIDA_TELEFONO.MENSAGE) :
      true;
  }

  validaContrasena(contrasena: string) {
    this.usuario.contrasena.length <= contrasena.length ?
    this.usuario.contrasena === contrasena ? true: Swal.fire(VALIDA_CONTRASENA.TITULO, VALIDA_CONTRASENA.MENSAGE, 'warning')
      : true;
  }

  fijarFecha() {
    this.usuario.formatFechaNacimiento = moment(arguments[0].value).format('DD/MM/YYYY');
    this.muestraSelectorFecha = !this.muestraSelectorFecha;
  }

  ObtenerDatosUsuario(usuario: Usuario) {
    this.crudusuarioService.buscarUsuario(usuario).subscribe(
      success => {
        this.crudusuarioService.setUsuario(success[0]);
        this.usuario = this.crudusuarioService.getUsuario();
      }, error => {
        this.usuario = this.crudusuarioService.getUsuario();
      }
    );
  }

  redireccionEspacioUrbano() {
    this.authService.logOut();
    window.location.href = URL_ESPACIO_URBANO;
  }

  logOut() {
    this.authService.logOut();
    this.router.navigate(['new-login']);
  }

  getPaisById(id: number): Pais{
    return this.paises.find(e=> e.id == id);
  }
}
