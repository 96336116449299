import { Component, OnInit } from '@angular/core';
import { PuntoAcceso } from 'src/app/models/punto-acceso';
import { CrudpuntoAccesoService } from 'src/app/services/crud/crudpunto-acceso.service';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-lista-puntos',
  templateUrl: './lista-puntos.component.html',
  styleUrls: []
})
export class ListaPuntosComponent implements OnInit {
  puntos: PuntoAcceso[];
  pagActual: number;
  paginador: any;

  constructor(public crudPunto: CrudpuntoAccesoService) {}

  ngOnInit() {
    this.cargarPuntos(0);
  }

  cargarPuntos(page: number): void {
    this.pagActual = page;
    this.crudPunto.getPuntosPag(page).subscribe(response => {
      this.puntos = response.content;
      this.paginador = response;
    });
  }

  nuevoIndent({ ...puntoIdent }: PuntoAcceso) {
    Swal.fire({
      title: 'Identificador actual: ' + puntoIdent.identificador,
      text: 'Ingrese el nuevo identificador: ',
      input: 'text',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Cambiar'
    }).then(result => {
      if (!result.dismiss) {
        if (result.value !== '') {
          if (result.value.length > 3) {
            if (puntoIdent.identificador !== result.value) {
              puntoIdent.identificador = result.value.trim();

              let puntoBuscIdent = new PuntoAcceso();
              puntoBuscIdent.identificador = result.value;

              this.crudPunto.buscarPuntoFiltro(puntoBuscIdent).subscribe(
                result => {
                  if (result.length === 1) {
                    Swal.fire(
                      'El identificador ya existe en la base de datos, intenta con otro'
                    );
                  } else if (result.length === 0) {
                    this.actualizarPunto(puntoIdent);
                  }
                },
                err => {
                  if (err.status === 500 || err.status === 0) {
                    Swal.fire({
                      title: err.error.error,
                      text: err.error.message,
                      confirmButtonText: 'OK'
                    });
                    //Otros errores controlados desde el back
                  } else {
                    Swal.fire({
                      title: err.error.mensaje,
                      confirmButtonText: 'OK'
                    });
                  }
                }
              );
              // this.actualizarCampana(puntoIdent, 0);
            } else {
              Swal.fire('No puedes ingresar el mismo identificador.');
            }
          } else {
            Swal.fire('El identificador debe tener al menos 4 caracteres.');
          }
        } else {
          Swal.fire('Debes ingresar un identificador.');
        }
      }
    });
  }

  actualizarPunto(puntoAct: PuntoAcceso) {
    this.crudPunto.actualizarPunto(puntoAct).subscribe(
      () => {
        this.cargarPuntos(this.pagActual);
        Swal.fire('Punto de Acceso Actualizado');
      },
      err => {
        
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);

        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  eliminarPunto(puntoDel: PuntoAcceso) {
    Swal.fire({
      title:
        '¿Estás seguro que quieres eliminar ' + puntoDel.identificador + ' ?',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value === true) {
        this.crudPunto.delete(puntoDel.id).subscribe(
          () => {
            this.puntos = this.puntos.filter(camp => camp !== puntoDel);
            Swal.fire('Punto de Acceso eliminado');
          },
          err => {
            if (err.error.message) {
              Swal.fire({
                title: err.error.error,
                text: err.error.message,
                confirmButtonText: 'OK'
              });
            } else {
              Swal.fire({
                title: err.error.mensaje,
                confirmButtonText: 'OK'
              });
            }
          }
        );
      }
    });
  }
}
