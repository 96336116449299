import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './pages/signin/signin.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { LoginComponent } from './pages/login/login.component';
import { ActualizarDatosComponent } from './pages/actualizar-datos/actualizar-datos.component';
import { AdministracionComponent } from './pages/administracion/administracion.component';
import { ValidarCorreoComponent } from './pages/validar-correo/validar-correo.component';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import {NewLoginComponent} from './pages/new-login/new-login.component';

const routes: Routes = [
  { path: '', component: SigninComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'login', component: LoginComponent },
  { path: 'actualizar_datos', component: ActualizarDatosComponent },
  { path: 'administracion', component: AdministracionComponent, data: {titulo: 'Administracion'} },
  { path: 'validar_correo', component: ValidarCorreoComponent },
  { path: 'politicas', component: PoliticasComponent},
  { path: 'new-login', component: NewLoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
