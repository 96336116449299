import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class RedesSocialesService {
  provider: any;
  constructor(public afAuth: AngularFireAuth) { }

   getAuth() {
    console.log("gofin feccesbon "+JSON.stringify(this.afAuth.authState));
    return this.afAuth.authState.map(auth => auth);
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  loginTwitter() {
    return this.afAuth.auth.signInWithRedirect(new firebase.auth.TwitterAuthProvider());
  }

  loginFacebook() {

    return this.afAuth.auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider());


    /*var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    
    return this.afAuth.auth.signInWithPopup(provider);*/
  }


  loginGoogle() {
    return this.afAuth.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    /*const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
   // this.afAuth.signInWithPopup(googleAuthProvider);
    return this.afAuth.auth.signInWithPopup(provider);*/
  }

  loginFacebookandroiddesk() {

 


    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    
    return this.afAuth.auth.signInWithPopup(provider);
  }


  loginGoogleandoiddesk() {
  
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    return this.afAuth.auth.signInWithPopup(provider);
  }

  deleteUsuario() {
    var user = firebase.auth().currentUser;
    user.delete().then(function() {
      this.redSocialService.logout();
    }).catch(function(error) {
      // An error happened.
    });
  }

}
