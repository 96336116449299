export class Params {
  baseGrantUrl: string;
  clientMac: string;
  nodeMac: string;
  userContinueUrl: string;
  imagenLogo: string;
  tiempoCamp: number;
  htmlCamp: string;
  urlAcceso: string;
  tipoCampana: number;
}
