import { Component, OnInit, Input } from '@angular/core';
import { PuntoAcceso } from 'src/app/models/punto-acceso';
import { CrudespaciourbanoService } from 'src/app/services/crud/crudespaciourbano.service';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import Swal from 'sweetalert2';
import { CrudpuntoAccesoService } from 'src/app/services/crud/crudpunto-acceso.service';

@Component({
  selector: 'app-nuevo-punto-acceso',
  templateUrl: './nuevo-punto-acceso.component.html',
  styleUrls: []
})
export class NuevoPuntoAccesoComponent implements OnInit {
  @Input() punto: PuntoAcceso;
  @Input() espacios: EspacioUrbano[];

  constructor(
    public crudEspacio: CrudespaciourbanoService,
    public crudPunto: CrudpuntoAccesoService
  ) {}

  ngOnInit() {
    this.punto = new PuntoAcceso();
  }


  crear() {
    
    if (this.punto.espacio && this.punto.identificador) {
      this.punto.identificador = this.punto.identificador.trim();
      if (this.punto.identificador.length >= 4) {
        let puntoBusc = new PuntoAcceso();
        //Buscar identificador
        puntoBusc.identificador = this.punto.identificador;

        this.crudPunto.buscarPuntoFiltro(puntoBusc).subscribe(
          result => {
            if (result.length === 1) {
              Swal.fire(
                'El identificador ya existe en la base de datos, intenta con otro'
              );
            } else if (result.length === 0) {
              this.crearPunto();
            }
          },
          err => {
            if (err.error && err.error.mensaje) {
              Swal.fire(err.error.mensaje);

            } else if (err.status === 500 || err.status === 0) {
              Swal.fire('No hay conexión con la Base de Datos');
            }
          }
        );
      } else {
        Swal.fire('El identificador debe contener mínimo 4 caracteres');
      }
    } else {
      Swal.fire('Debe completar todos los campos');
    }
  }

  crearPunto() {
    this.crudPunto.crearPunto(this.punto).subscribe(
      () => {
        Swal.fire('Punto de Acceso creado').then(() =>
          window.location.reload()
        );
      },
      err => {
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);

        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }
}
