export const VALIDA_TELEFONO = {
  TITULO: 'Atención!',
  MENSAGE: 'Debes ingresar un número telefónico válido.'
};

export const CANTIDAD_CARACTERES_NUMERO = 8;

export const VALIDA_CONTRASENA = {
  TITULO: 'Atención!',
  MENSAGE: 'Las contraseñas ingresadas no coinciden.'
};

export const URL_ESPACIO_URBANO = 'https://www.espaciourbano.cl/';
