import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';
import { EspacioUrbano } from 'src/app/models/espacio-urbano';
import { Usuario } from 'src/app/models/usuario';
import { CrudpuntoAccesoService } from 'src/app/services/crud/crudpunto-acceso.service';
import { CrudusuarioService } from 'src/app/services/crud/crudusuario.service';
import Swal from 'sweetalert2';
import { CrudcampanaService } from 'src/app/services/crud/crudcampana.service';
import { Campana } from 'src/app/models/campana';
import { ParamsService } from 'src/app/services/params.service';
import { Params } from 'src/app/models/params';
import { SigninService } from './signin.service';
import {
  ESTADO_ACTIVO_ESPACIO_URBANO,
  ID_FACEBOOK,
  ID_GOOGLE,
  ID_TWITTER
} from './signin.constants';
import { IMAGEN_DEFAULT } from 'src/app/models/constants';
import { RUTA_IMG } from 'src/app/services/datos_api';
import { DomSanitizer } from '@angular/platform-browser';
import { RedesSocialesService } from 'src/app/services/redes-sociales.service';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

declare const window: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['../../../assets/css/spinner.css']
})
export class SigninComponent implements OnInit {
  paramsResp: Params;
  acep: boolean;
  usuario: Usuario;
  espacio: EspacioUrbano;
  logoImagen: any;
  macUsuario: string;
  macNodo: string;
  campana: Campana;
  rutaImg: any;
  visibilidad: boolean;
  continuar: boolean;
  contador: number;
  isApple: boolean;
  isAndroid: boolean;
  isdesktop: boolean;
  sonidoIcon: string;
  name: string;

  validandologin: boolean;

  constructor(
    private socialAuthService: AuthService,
    public crudusuarioService: CrudusuarioService,
    public router: Router,
    public crudpuntoService: CrudpuntoAccesoService,
    private route: ActivatedRoute,
    public crudCampanaService: CrudcampanaService,
    private signinService: SigninService,
    public paramsService: ParamsService,
    public sanitizer: DomSanitizer,
    public redSocialService: RedesSocialesService,
    public afAuth: AngularFireAuth
  ) {
    this.paramsResp = new Params();
    this.espacio = new EspacioUrbano();
    this.usuario = new Usuario();
    this.campana = new Campana();
    this.visibilidad = false;
    this.continuar = false;
    this.sonidoIcon = 'volume_off';
    this.isApple = false;
    this.isAndroid = false;
    this.isdesktop = false;
    this.validandologin=false;
  }

  ngOnInit() {



    this.validandologin=false;
    this.name="";
   // localStorage.clear();
   // console.log("cleaner localStorage")
   // sessionStorage.clear()
    console.log(" login> 1");
    this.obtenerParametros();
    console.log(" login> 2");
    // VOLVER A CARGAR FONDO
    document.documentElement.style.backgroundImage = `url('assets/img/Fondo_Espacio_Urbano.jpg')`;
    this.reconocerApple();
    console.log(" login> 3");
    this.cargarParams();

    console.log(" login> 4");
    this.validandologin=true;
  }

  reconocerApple() {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const userAndroid = navigator.userAgent.match(/Android/i);
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.isApple = true;
    }else if(userAndroid){
      this.isAndroid=true;
    }else{
      this.isdesktop=true;

    }
   


  }

  cargarParams() {
    this.paramsResp = this.paramsService.getParams();
    this.cargarImagen();
    this.autenticarUsuarioRedesSociales();
  }

  crearUsuario() {
    this.crudusuarioService.crearUsuario(this.usuario).subscribe(
      () => {
        this.mostrarPublicidad();
      },
      err => {
        if (err.error && err.error.mensaje) {
          Swal.fire(err.error.mensaje);
        } else if (err.status === 500 || err.status === 0) {
          Swal.fire('No hay conexión con la Base de Datos');
        }
      }
    );
  }

  goRegistro() {
    if (this.acep) {
      this.router.navigate(['registro']);
    } else {
      Swal.fire('Debes aceptar los términos y condiciones.');
    }
  }

  goLogin() {
    if (this.acep) {
      this.router.navigate(['login']);
    } else {
      Swal.fire('Debes aceptar los términos y condiciones.');
    }
  }

  // Login por redes sociales
  public socialSignIn(socialPlatform: string) {
    if (this.acep) {
      if (this.paramsResp) {
        this.usuario.nodo = this.paramsResp.nodeMac;
        this.usuario.mac = this.paramsResp.clientMac;
        let socialPlatformProvider;
        if (socialPlatform === 'facebook') {
          socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
          this.usuario.idRedSocial = ID_FACEBOOK;
        } else if (socialPlatform === 'google') {
          socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
          this.usuario.idRedSocial = ID_GOOGLE;
        }

        this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
          console.error(">>>>>>>>>>>>> Este el login >>>>>>>>>>>>>< ");
          console.error(userData);
          // Obtener datos de la red social
          this.usuario.nombre = userData.name;
          this.usuario.email = userData.email;
          this.usuario.contrasena = userData.id;
          this.name=userData.name;
          // Hacer login con la cuenta de las Redes Sociales (correo= usuario, id= contraseña)
          this.crudusuarioService.loginRRSS(this.usuario).subscribe(
            response => {
              // En el caso de que el usuario ya este en la BD se muestra la publicidad
              this.mostrarPublicidad();
            },
            err => {
              // Si no esta el usuario, se ingresa
              if (err.status === 404) {
                this.crearUsuario();
              } else {
                if (err.status === 500) {
                  Swal.fire('Hubo un error en la base de datos.');
                }
              }
            }
          );
        });
      } else {
        // En caso de que se pierdan los parámetros al hacer login
        Swal.fire(
          'Se perdieron los parámetros para el acceso a internet.'
        );
      }
    } else {
      Swal.fire('Debes aceptar los términos y condiciones.');
    }
  }

  public signOut(){
    this.socialAuthService.signOut();

}
  autenticarUsuarioRedesSociales() {


    

   
   // if (this.isApple) {
   //  console.log("this.paramsResp "+JSON.stringify(this.paramsResp));
     this.redSocialService.getAuth().subscribe(res => {
       
        this.validandologin=true;
        if (res) {
          if (this.paramsResp) {

          ///  console.log("Padilla><<>>>>> "+res);
            if (res.providerData[0].email) {
              Swal.fire({ title: 'Iniciando sesión.', onBeforeOpen: () => { Swal.showLoading(); } });
              this.usuario.email = res.providerData[0].email;
              this.usuario.nombre = res.providerData[0].displayName;
              this.usuario.contrasena = res.providerData[0].uid;
              const redSocial = res.providerData[0].providerId.split('.')[0];
              this.usuario.nodo = this.paramsResp.nodeMac;
              this.usuario.mac = this.paramsResp.clientMac;

              if (redSocial === 'facebook') {
                this.usuario.idRedSocial = ID_FACEBOOK;
              } else if (redSocial === 'twitter') {
                this.usuario.idRedSocial = ID_TWITTER;
              }else  {
                this.usuario.idRedSocial = ID_GOOGLE;
              }

              this.name=res.providerData[0].displayName;

              console.log(this.usuario);
              this.crudusuarioService.loginRRSS(this.usuario).subscribe(
                () => {
                  // En el caso de que el usuario ya este en la BD se muestra la publicidad
                  this.borrarCerrarSocial();
                  this.mostrarPublicidad();

                },
                err => {
                  // Si no esta el usuario, se ingresa
                  this.borrarCerrarSocial();
                  if (err.status === 404) {
                    this.crearUsuario();
                    // this.paramsResp;
                  } else {
                    if (err.status === 500 || err.status == 0) {
                      Swal.fire('Hubo un error en la base de datos.');
                    }
                  }
                }
              );
            } else {
              Swal.fire('Redireccion', 'Dada la configuracion de privacidad establecida en tu cuenta, no es posible el registro por esta red social. Serás redirigido a un portal de registro manual').then(() => {
                this.borrarCerrarSocial();
                this.router.navigate(['registro']);
              });
            }
          } else {
            Swal.fire('Ocurrió un problema al intentar darte acceso a internet, por favor, intentalo más tarde.');
            this.borrarCerrarSocial();
          }
        }

      }, err => {
        this.validandologin=true;
        this.borrarCerrarSocial();
        Swal.fire(err.message);

      });
    //}*/
  }

  borrarCerrarSocial() {
    this.redSocialService.deleteUsuario();
    this.redSocialService.logout();
  }

  obtenerParametros() {
    this.route.queryParams.subscribe(params => {
      if (params.node_mac) {
        // Se prepara un espacio para buscar logo
        this.espacio.identificador = params.node_mac;
        this.espacio.status = ESTADO_ACTIVO_ESPACIO_URBANO;

        // Se guardan los parametros en un entity para luego guardarlo en sesión
        this.paramsResp.userContinueUrl = params.user_continue_url;
        this.paramsResp.clientMac = params.client_mac;
        this.paramsResp.nodeMac = params.node_mac;
        this.paramsResp.baseGrantUrl = params.base_grant_url;
        this.paramsResp.urlAcceso =
          this.paramsResp.baseGrantUrl +
          '?continue_url=' +
          this.paramsResp.userContinueUrl;
        this.setParametros();

        // Buscar un espacio segun el identificador
        this.crudpuntoService.buscarPuntoActivo(this.espacio).subscribe(
          puntoAccesoResp => {
            if (puntoAccesoResp.length > 0) {
              // Si se encuentra el espacio, se carga el logo y se busca la publicidad
              this.paramsResp.imagenLogo = puntoAccesoResp[0].espacio.imagen;

              this.cargarPublicidad(puntoAccesoResp[0].espacio);
            } else {
              // Si no se encuentra ningun espacio se setea la sesión
              this.setParametros();
            }
            this.cargarImagen();
          },
          err => {
            this.cargarImagen();
            // TODO:
            // mostrar error en caso de que haya uno?
          }
        );
      } else {
        this.cargarParams();
      }
    });
  }

  // se asigna el logo a la variable
  cargarImagen() {
    if (this.paramsResp !== null && this.paramsResp.imagenLogo) {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + this.paramsResp.imagenLogo
      );
    } else {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + IMAGEN_DEFAULT
      );
    }
    // Envia al principio de la pagina, luego de cargar la imagen
    window.scroll(0, 0);
  }

  mostrarTerminos() {
    this.signinService.getTerminosYCondiciones().subscribe(terminos => {
      Swal.fire({
        //  @ts-ignore
        title: terminos.titulo,
        //  @ts-ignore
        html: terminos.texto
      });
    });
  }

  setParametros() {
    this.paramsService.setParams(this.paramsResp);
  }

  cargarPublicidad(espacioPub: EspacioUrbano) {
    this.campana.espacio = espacioPub;
    this.campana.status = 1;
    // se busca una campaña segun el espacio urbano
    this.crudCampanaService.getCampanaFiltro(this.campana).subscribe(
      response => {

        // se cargan el tiempo y la publicidad
        if (response.length > 0) {
          this.paramsResp.tiempoCamp = response[0].tiempo;
          this.paramsResp.htmlCamp = RUTA_IMG + response[0].publicidad;
          this.paramsResp.tipoCampana = this.getTipoCampana(response[0].publicidad);
        }
        this.setParametros();
      },
      err => {
        this.setParametros();
      }
    );
  }

  mostrarPublicidad() {
    Swal.close();
    // Valida que se tengan los paramentros
    if (this.paramsResp) {
      // Si tiene tiempo, significa que tiene publicidad
      if (this.paramsResp.tiempoCamp) {
        this.visibilidad = true;
        this.contador = this.paramsResp.tiempoCamp;

        const intervalo = setInterval(
          function () {
            if (this.contador > 0) {
              this.contador--;
              intervalo;
            } else {
              this.continuar = true;
            }
          }.bind(this),
          // Si no tiene publicidad, se redirecciona
          1000
        );
      } else {
        window.location.href = this.paramsResp.urlAcceso;
      }
    } else {
      Swal.fire('Se perdieron los parámetros para el acceso a internet.');
    }
  }

  getTipoCampana(campana: string): number {
    const extension = campana.substr(campana.lastIndexOf('.') + 1).toLowerCase();
    if (extension === 'mp4' || extension === 'web' || extension === 'mov' || extension === 'vm4') {
      // 1 ES PARA VIDEOS
      return 1;
    }
    // 2 ES PARA IMAGENES
    return 2;
  }

  cambiarSonido() {
    const video = document.getElementById('myVideo') as HTMLMediaElement;
    if (video.muted) {
      // Mutear
      video.muted = false;
      this.sonidoIcon = 'volume_up';
    } else {
      // Desmutear
      video.muted = true;
      this.sonidoIcon = 'volume_off';
    }
  }



  loginFaceIOS(redSocial: string) {
    if (this.acep) {
      if (this.paramsResp) {
        if (redSocial === 'twitter') {
          this.redSocialService.loginTwitter().then(res => {

          }, err => {
            Swal.fire('El error fue: ' + err.code + ' ' + err.message);
          }).catch(
            err => Swal.fire(err.code + ' ' + err.message)
          );
        }

        else if (redSocial === 'facebook') {
          this.redSocialService.loginFacebook()
            .then(() => { }, err => {
              Swal.fire('El error fue: ' + err.code + ' ' + err.message);
            }).catch(
              err => {
                Swal.fire(err.code + ' ' + err.message);

              }
            );
        }
        else {
   
          this.redSocialService.loginGoogle()
            .then(() => { }, err => {
              Swal.fire('El error fue: ' + err.code + ' ' + err.message);
            }).catch(
              err => {
                Swal.fire(err.code + ' ' + err.message);

              }
            );       }
      } else {
        // En caso de que se pierdan los parámetros al hacer login
        Swal.fire(
          'Se perdieron los parámetros para el acceso a internet.'
        );
      }
    } else {
      Swal.fire('Debes aceptar los términos y condiciones.');
    }
  }


  loginFaceDesktop(redSocial: string) {
    if (this.acep) {
      if (this.paramsResp) {
        if (redSocial === 'twitter') {
          this.redSocialService.loginTwitter().then(res => {

          }, err => {
            Swal.fire('El error fue: ' + err.code + ' ' + err.message);
          }).catch(
            err => Swal.fire(err.code + ' ' + err.message)
          );
        }

        else if (redSocial === 'facebook') {
          this.redSocialService.loginFacebookandroiddesk()
            .then(() => { }, err => {
              Swal.fire('El error fue: ' + err.code + ' ' + err.message);
            }).catch(
              err => {
                Swal.fire(err.code + ' ' + err.message);

              }
            );
        }
        else {
   
          this.redSocialService.loginGoogleandoiddesk()
            .then(() => { }, err => {
              Swal.fire('El error fue: ' + err.code + ' ' + err.message);
            }).catch(
              err => {
                Swal.fire(err.code + ' ' + err.message);

              }
            );       }
      } else {
        // En caso de que se pierdan los parámetros al hacer login
        Swal.fire(
          'Se perdieron los parámetros para el acceso a internet.'
        );
      }
    } else {
      Swal.fire('Debes aceptar los términos y condiciones.');
    }
  }

  redireccionar() {
    window.location.href = this.paramsResp.urlAcceso;
  }







}
