import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IMAGEN_DEFAULT } from 'src/app/models/constants';
import { Pais } from 'src/app/models/localidad/pais';
import { Params } from 'src/app/models/params';
import { Usuario } from 'src/app/models/usuario';
import { CrudusuarioService } from 'src/app/services/crud/crudusuario.service';
import { RUTA_IMG } from 'src/app/services/datos_api';
import { LocalidadService } from 'src/app/services/localidad.service';
import { ParamsService } from 'src/app/services/params.service';
import { ValidacionesService } from 'src/app/services/util/validaciones.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import { SIN_RED_SOCIAL } from './registro.constants';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styles: []
})
export class RegistroComponent implements OnInit {
  contrasena2: string;
  usuario: Usuario;
  paises: Pais[];
  usuarios: Usuario[];
  logoImagen: any;
  paramsResp: Params;
  rutaImg: string;
  visibilidad: Boolean;
  continuar: Boolean;
  contador: number;
  sonidoIcon: string;
  muestraSelectorFecha: boolean;

  constructor(
    public crudusuarioService: CrudusuarioService,
    public localidadService: LocalidadService,
    public paramsService: ParamsService,
    public sanitizer: DomSanitizer,
    private validacionesServices: ValidacionesService
  ) {
    this.usuario = new Usuario();
    this.paramsResp = new Params();
    this.logoImagen = this.paramsResp.imagenLogo;
    this.rutaImg = RUTA_IMG;
    this.visibilidad = false;
    this.continuar = false;
    this.sonidoIcon = 'volume_off';
  }

  ngOnInit() {
    this.cargarParams();
    this.cargarPaises();
  }

  cargarParams() {
    this.paramsResp = this.paramsService.getParams();
    this.cargarImagen();
  }

  cargarImagen() {
    if (this.paramsResp !== null && this.paramsResp.imagenLogo) {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + this.paramsResp.imagenLogo
      );
    } else {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + IMAGEN_DEFAULT
      );
    }
    // Envia al principio de la pagina, luego de cargar la imagen
    window.scroll(0, 0);
  }

  // Metodo para crear usuario
  crear() {
    if (this.paramsResp) {
      Swal.fire({
        title: 'Registrando usuario', onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      this.usuario.nodo = this.paramsResp.nodeMac;
      this.usuario.mac = this.paramsResp.clientMac;
      this.usuario.idRedSocial = SIN_RED_SOCIAL;
      this.usuario.email = this.usuario.email.toLowerCase();

      // se llama el metodo para crear desde el servicio crud usuario
      this.crudusuarioService.crearUsuario(this.usuario).subscribe(
        () => {
          Swal.close();
          // Se muestra la publicidad
          this.mostrarPublicidad();
        },
        err => {
          if (err.error && err.error.mensaje) {
            Swal.fire(err.error.mensaje);
          } else if (err.status === 500 || err.status === 0) {
            Swal.fire('No hay conexión con la Base de Datos');
          } else {
            Swal.fire('Hubo un error al intentar crear el usuario.');
          }
        }
      );
    } else {
      Swal.fire(
        'Ocurrió un problema al intentar registrarte, por favor, intentalo más tarde.'
      );
    }
  }

  cargarPaises() {
    this.localidadService.getLocalidad('pais').subscribe(paises => {
      this.paises = paises;
    }, err => {
      Swal.fire('Error al intentar cargar los paises.');
    }
    );
    this.usuario.idPaisTelefono = 41;
  }

  validarUsuario() {
    // Validar que los campos esten completados
    if (
      this.usuario.email &&
      this.usuario.nombre &&
      this.usuario.contrasena &&
      this.usuario.telefono &&
      this.usuario.apellido &&
      this.contrasena2 &&
      // this.usuario.genero &&
      // this.usuario.formatFechaNacimiento &&
      // this.usuario.hijos !== undefined &&
      1 == 1
    ) {
      this.usuario.email = this.usuario.email.trim();
      // Se verifica que las contraseñas sean iguales
      if (this.usuario.contrasena !== this.contrasena2) {
        Swal.fire(`Las contraseñas no coinciden`);
        // Condiciones mínimas para los campos
      } else if (
        this.usuario.nombre.length >= 3 &&
        this.validarCorreo() &&
        this.validarTelefono(this.getPaisById(this.usuario.idPaisTelefono), this.usuario.telefono) &&
        this.usuario.apellido.length >= 3 &&
        this.usuario.contrasena.length >= 4 &&
        this.validarFechaNacimiento(this.usuario.formatFechaNacimiento)
      ) {
        this.crear();

      } else {
        if (this.usuario.nombre.length < 3) {
          Swal.fire('Nombres debe contener como mínimo 3 letras');
        } else if (this.usuario.apellido.length < 3) {
          Swal.fire('Apellidos debe contener como mínimo 3 letras');
        } else if (!this.validarCorreo()) {
          Swal.fire('Correo inválido');
        } else if (this.usuario.contrasena.length < 4) {
          Swal.fire('La contraseña debe contener como mínimo 4 caracteres');
        } else if (!this.validarTelefono(this.getPaisById(this.usuario.idPaisTelefono), this.usuario.telefono)) {
          Swal.fire('Debe ingresar un teléfono válido');
        } else if (!this.validarFechaNacimiento(this.usuario.formatFechaNacimiento)) {
          Swal.fire('La fecha de nacimiento debe ser menor a la fecha actual');
        }
      }

    } else {
      Swal.fire('Debe completar todos los campos para crear el usuario');
    }
  }



  validarFechaNacimiento(formatFechaNacimiento: string): boolean {
    if(!formatFechaNacimiento || formatFechaNacimiento === ''){
      return true;
    }
    const fechaNacimiento = moment(formatFechaNacimiento);

    const fechaActual = moment();

    return fechaNacimiento <= fechaActual;

  }

  validarCorreo(): boolean {
    const regex = '^[\\w-_\\.+]*[\\w-_\\.]\\@([\\w]+\\.)+[\\w]+[\\w]$';

    if (!this.usuario.email.match(regex)) {
      return false;
    } else {
      return true;
    }
  }

  validarTelefono(pais: Pais, numero: number|string): boolean {
    if(!numero){
      return true;
    }
    return this.validacionesServices.validarTelefonoV2(pais,numero);
  }

  mostrarPublicidad() {
    Swal.close();
    // Valida que se tengan los paramentros
    if (this.paramsResp) {
      // Si tiene tiempo, significa que tiene publicidad
      if (this.paramsResp.tiempoCamp) {
        this.visibilidad = true;
        this.contador = this.paramsResp.tiempoCamp;

        let intervalo = setInterval(
          function () {
            if (this.contador > 0) {
              this.contador--;
              intervalo;
            } else {
              this.continuar = true;
            }
          }.bind(this),
          1000
        );
        // Si no tiene publicidad, se redirecciona
      } else {
        window.location.href = this.paramsResp.urlAcceso;
      }
    } else {
      Swal.fire(
        'Ocurrió un problema al intentar darte acceso a internet, por favor, intentalo más tarde.'
      );
    }
  }

  cambiarSonido() {
    let video = document.getElementById('myVideo') as HTMLMediaElement;

    if (video.muted) {
      video.muted = false;
      this.sonidoIcon = 'volume_up';
    } else {
      video.muted = true;
      this.sonidoIcon = 'volume_off';
    }
  }

  redireccionar() {
    window.location.href = this.paramsResp.urlAcceso;
  }


  fijarFecha() {
    this.usuario.formatFechaNacimiento = moment(arguments[0].value).format('DD/MM/YYYY');
    this.muestraSelectorFecha = !this.muestraSelectorFecha;
  }

  getPaisById(id: number): Pais{
    return this.paises.find(e=> e.id == id);
  }

}
