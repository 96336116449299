import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './pages/signin/signin.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angular5-social-login';
import { RegistroComponent } from './pages/registro/registro.component';
import { LoginComponent } from './pages/login/login.component';
import { ActualizarDatosComponent } from './pages/actualizar-datos/actualizar-datos.component';
import { NuevoLogoComponent } from './pages/administrar-logos/nuevo-logo/nuevo-logo.component';
import { ListaLogosComponent } from './pages/administrar-logos/lista-logos/lista-logos.component';
import { NuevaCampanaComponent } from './pages/administrar-campanas/nueva-campana/nueva-campana.component';
import { AdministracionComponent } from './pages/administracion/administracion.component';
import { ListaCampanasComponent } from './pages/administrar-campanas/lista-campanas/lista-campanas.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { TabsModule } from 'ngx-tabset';
import { NuevoPuntoAccesoComponent } from './pages/nuevo-punto-acceso/nuevo-punto-acceso.component';
import { ListaPuntosComponent } from './pages/lista-puntos/lista-puntos.component';
import { ValidarCorreoComponent } from './pages/validar-correo/validar-correo.component';
import { DatePipe } from '@angular/common';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import { FACEBOOK_APP_ID, GOOGLE_APP_ID } from './pages/signin/signin.constants';
import { MomentModule } from 'ngx-moment';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { NewLoginComponent } from './pages/new-login/new-login.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

// Configs
export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(FACEBOOK_APP_ID) // Espacio Urbano
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(GOOGLE_APP_ID)
      },
    ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    RegistroComponent,
    LoginComponent,
    ActualizarDatosComponent,
    NuevoLogoComponent,
    ListaLogosComponent,
    NuevaCampanaComponent,
    AdministracionComponent,
    ListaCampanasComponent,
    PaginatorComponent,
    NuevoPuntoAccesoComponent,
    ListaPuntosComponent,
    ValidarCorreoComponent,
    PoliticasComponent,
    NewLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    FormsModule,
    HttpClientModule,
    TabsModule.forRoot(),
    MomentModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  providers: [
    { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
