import {Component, OnInit} from '@angular/core';
import {
  ESTADO_ACTIVO_ESPACIO_URBANO,
  USUARIO_NO_ENCONTRADO
} from '../signin/signin.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {EspacioUrbano} from '../../models/espacio-urbano';
import {CrudpuntoAccesoService} from '../../services/crud/crudpunto-acceso.service';
import {RUTA_IMG} from '../../services/datos_api';
import {IMAGEN_DEFAULT} from '../../models/constants';
import {DomSanitizer} from '@angular/platform-browser';
import {Params} from '../../models/params';
import Swal from 'sweetalert2';
import {Usuario} from '../../models/usuario';
import {Campana} from '../../models/campana';
import {CrudusuarioService} from '../../services/crud/crudusuario.service';
import {SigninService} from '../signin/signin.service';
import {ParamsService} from '../../services/params.service';
import {AuthInterceptorService} from '../../services/auth-interceptor.service';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.css']
})
export class NewLoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private puntoAccesoService: CrudpuntoAccesoService,
    private sanitizer: DomSanitizer,
    public crudusuarioService: CrudusuarioService,
    public router: Router,
    public crudpuntoService: CrudpuntoAccesoService,
    private signinService: SigninService,
    public paramsService: ParamsService,
    private authinterceptorservice: AuthInterceptorService
  ) {
    this.paramsResp = new Params();
    this.espacio = new EspacioUrbano();
    this.usuario = new Usuario();
    this.campana = new Campana();
    this.visibilidad = false;
    this.continuar = false;
  }

  espacio: EspacioUrbano;
  logoImagen: any;
  usuario: Usuario;
  paramsResp: Params;
  campana: Campana;
  visibilidad: boolean;
  continuar: boolean;

  ngOnInit() {
    this.obtenerParametros();
    document.documentElement.style.backgroundImage = `url('assets/img/Fondo_Espacio_Urbano.jpg')`;
  }

  cargarParams() {
    this.paramsResp = this.paramsService.getParams();
    this.cargarImagen();
  }

  cargarImagen() {
    if (this.paramsResp !== null && this.paramsResp.imagenLogo) {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + this.paramsResp.imagenLogo
      );
    } else {
      this.logoImagen = this.sanitizer.bypassSecurityTrustUrl(
        RUTA_IMG + IMAGEN_DEFAULT
      );
    }
    window.scroll(0, 0);
  }

  obtenerParametros() {
    this.route.queryParams.subscribe(params => {
      if (params.node_mac) {
        // Se prepara un espacio para buscar logo
        this.espacio.identificador = params.node_mac;
        this.espacio.status = ESTADO_ACTIVO_ESPACIO_URBANO;

        // Se guardan los parametros en un entity para luego guardarlo en sesión
        this.paramsResp.userContinueUrl = params.user_continue_url;
        this.paramsResp.clientMac = params.client_mac;
        this.paramsResp.nodeMac = params.node_mac;
        this.paramsResp.baseGrantUrl = params.base_grant_url;
        this.paramsResp.urlAcceso =
          this.paramsResp.baseGrantUrl +
          '?continue_url=' +
          this.paramsResp.userContinueUrl;
        this.setParametros();

        // Buscar un espacio segun el identificador
        this.crudpuntoService.buscarPuntoActivo(this.espacio).subscribe(
          puntoAccesoResp => {
            if (puntoAccesoResp.length > 0) {
              // Si se encuentra el espacio, se carga el logo y se busca la publicidad
              this.paramsResp.imagenLogo = puntoAccesoResp[0].espacio.imagen;

              // this.cargarPublicidad(puntoAccesoResp[0].espacio);
            } else {
              // Si no se encuentra ningun espacio se setea la sesión
              this.setParametros();
            }
            this.cargarImagen();
          },
          err => {
            this.cargarImagen();
            // TODO:
            // mostrar error en caso de que haya uno?
          }
        );
      } else {
        this.cargarParams();
      }
    });
  }

  setParametros() {
    this.paramsService.setParams(this.paramsResp);
  }

  redireccionar() {
    this.router.navigate(['actualizar_datos']);
  }

  ingresar() {
    if (this.paramsResp) {
      this.usuario.mac = this.paramsResp.clientMac;
    }
    if (this.validarUsuario()) {
      this.usuario.email = this.usuario.email.toLowerCase();
      this.crudusuarioService.login(this.usuario).subscribe(
        response => {
          this.crudusuarioService.setUsuario(response.user);
          this.authinterceptorservice.setToken(response.access_token, response.expires_in);
          this.redireccionar();
        },
        err => {
          if (err.status === USUARIO_NO_ENCONTRADO) {
            Swal.fire(`Correo o contraseña errónea`);
          } else if (err.error && err.error.mensaje) {
            Swal.fire(err.error.mensaje);
          } else if (err.status === 500 || err.status === 0) {
            Swal.fire('No hay conexión con la Base de Datos');
          }
        }
      );
    }
  }

  validarUsuario(): boolean {
    // Validar que se haya ingresado contraseña y correo
    if (this.usuario.email && this.usuario.contrasena) {
      // Validar que el correo y la contraseña cumplan con las condiciones minimas
      if (this.validarCorreo() && this.usuario.contrasena.length >= 4) {
        return true;
      } else {
        if (!this.validarCorreo()) {
          Swal.fire('Debes ingresar un correo válido.');
        } else {
          Swal.fire('La contraseña debe contener como mínimo 4 caracteres');
        }
        return false;
      }
    } else {
      Swal.fire('Debes completar todos los campos');
      return false;
    }
  }

  validarCorreo(): boolean {
    const regex = '^[\\w-_\\.+]*[\\w-_\\.]\\@([\\w]+\\.)+[\\w]+[\\w]$';

    if (!this.usuario.email.match(regex)) {
      return false;
    } else {
      return true;
    }
  }

  recuperarContrasena() {
    Swal.fire({
      title: 'Recuperar Contraseña',
      input: 'email',
      cancelButtonText: 'Cancelar',
      inputPlaceholder: 'Ingresa tu correo electrónico.',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: 'Buscando usuario.', onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        this.crudusuarioService.recuperarContrasena(result.value).subscribe(
          rs => {
            Swal.fire('Contraseña enviada a: ' + result.value);
          },
          err => {
            if (err.error && err.error.mensaje) {
              Swal.fire(err.error.mensaje);
            } else if (err.status === 500 || err.status === 0) {
              Swal.fire('No hay conexión con la Base de Datos');
            } else if (err.status === 404) {
              Swal.fire({
                title: 'Usuario no registrado.',
                showCancelButton: true,
                confirmButtonText: 'Registrarme',
                cancelButtonText: 'Cancelar'
              }).then((resultado) => {
                if (!resultado.dismiss) {
                  this.router.navigate(['registro']);
                }
              });
            }
          }
        );
      }
    });
  }

}
